import React, { createContext, useContext, useRef, useState } from 'react';
import { StateSelector } from 'zustand';

import { createOrderSelectionStore, OrderSelectionStore } from './OrderSelectionStore';
import { OrderStore, createOrderStore } from './OrderStore';
import { equalityFn } from '../equalityFn';
import { Store } from '@Core/createStore';

export interface CheckoutStoreContextValue {
  order: Store<OrderStore>;
  orderSelection: Store<OrderSelectionStore>;
}

export interface InitialValues {
  order?: Partial<OrderStore>;
}

const CheckoutStoreContext = createContext<CheckoutStoreContextValue>(
  {} as CheckoutStoreContextValue,
);

export const useCheckoutStoreContext = () => useContext(CheckoutStoreContext);

export const CheckoutStoreContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [stores] = useState<CheckoutStoreContextValue>(() => ({
    order: createOrderStore(),
    orderSelection: createOrderSelectionStore(),
  }));

  return <CheckoutStoreContext.Provider value={stores}>{children}</CheckoutStoreContext.Provider>;
};

export const CheckoutStoreOverride: React.FC<
  React.PropsWithChildren<{ initialValues?: InitialValues }>
> = ({ children, initialValues = {} }) => {
  const value = useCheckoutStoreContext();
  const initialStateApplied = useRef<boolean>(false);

  if (!initialStateApplied.current) {
    Object.entries(value).forEach(([, store]) => {
      store.destroy();
    });

    Object.entries(initialValues).forEach(([name, store]) => {
      value[name as keyof InitialValues]?.getState().setInitialValues(store);
    });

    initialStateApplied.current = true;
  }

  return <CheckoutStoreContext.Provider value={value}>{children}</CheckoutStoreContext.Provider>;
};

export const useOrderStore: <U>(selector: StateSelector<OrderStore, U>) => U = (selector) =>
  useContext(CheckoutStoreContext).order(selector, equalityFn);

export const useOrderSelectionStore: <U>(selector: StateSelector<OrderSelectionStore, U>) => U = (
  selector,
) => useContext(CheckoutStoreContext).orderSelection(selector, equalityFn);
